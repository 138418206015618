import dayjs from '@eversity/services/dayjs';
import { type StudentCourseOptionalLesson } from '@eversity/types/domain';
import { type IUserOptionalLesson } from '@eversity/types/node';

/**
 * Get number of days before the lesson will be available to the user.
 *
 * @param userAccessStartDate - Date when the user has been registered to a course.
 * @param lessonAvailableAfterDays - Number of days after the access start date
 * before the lesson is available to the user
 * @returns Days remaining.
 */
export const getRemainingDaysBeforeLessonIsAvailable = (
  userAccessStartDate: string,
  lessonAvailableAfterDays: number,
) =>
  dayjs(userAccessStartDate)
    .add(lessonAvailableAfterDays + 1, 'day')
    .diff(dayjs(), 'day');

/**
 * Check if a student has activated the lesson as optional.
 *
 * @param optionalLessons - Student's course optional lessons.
 * @param lessonId - Lesson id to check.
 * @returns Student has lesson has option.
 */
export const hasStudentActivatedOptionalLesson = (
  optionalLessons: (StudentCourseOptionalLesson | IUserOptionalLesson)[],
  lessonId: string,
) =>
  !!optionalLessons.find(
    ({ lesson, enabled }) => lesson.toString() === lessonId && enabled,
  );

import { type NOTE_TYPES } from '@eversity/domain/constants';
import { serializeVersion } from '@eversity/domain/versions';
import { type LessonStudentNote } from '@eversity/types/domain';
import { type INote } from '@eversity/types/node';

import { HttpRepository } from '../httpRepository';

const e = encodeURIComponent;

export class NotesRepository extends HttpRepository {
  /**
   * Get all students notes of a sequence.
   *
   * @param lessonId - Lesson id.
   * @param versionMajor - Major version.
   * @param versionMinor - Minor version.
   * @param sequenceVersionId - Sequence id.
   * @returns List of notes.
   */
  async getSequenceNotes(
    lessonId: string,
    versionMajor: number,
    versionMinor: number,
    sequenceVersionId: string,
  ): Promise<LessonStudentNote[]> {
    const { body: notes } = await this.http.get(
      `/api/v1/school/lessons/${e(lessonId)}/versions/${e(
        serializeVersion({ versionMajor, versionMinor }),
      )}/sequences/${e(sequenceVersionId)}/notes`,
    );

    return notes;
  }

  /**
   * Create a new comment on a block.
   *
   * @param params - Params to create the comment.
   * @param params.lessonId - Lesson id.
   * @param params.versionMajor - Major version.
   * @param params.versionMinor - Minor version.
   * @param params.sequenceVersionId - Sequence id.
   * @param params.blocks - Blocks concerned by the note.
   * @param params.content - Content of the comment.
   * @returns The created comment.
   */
  async postNote({
    lessonId,
    type,
    versionMajor,
    versionMinor,
    sequenceVersionId,
    updatedNote,
  }: {
    lessonId: string;
    type: NOTE_TYPES;
    versionMajor: number;
    versionMinor: number;
    sequenceVersionId: string;
    updatedNote: Pick<INote, 'content' | 'blocks'>;
  }): Promise<LessonStudentNote> {
    const { content, blocks } = updatedNote;

    const { body: note } = await this.http
      .post(
        `/api/v1/school/lessons/${e(lessonId)}/versions/${e(
          serializeVersion({ versionMajor, versionMinor }),
        )}/sequences/${e(sequenceVersionId)}/blocks/${e(Object.keys(blocks)[0])}/notes`,
      )
      .send({
        content,
        type,
        blocks,
      });

    return note;
  }

  /**
   * Update a note.
   *
   * @param params - Params.
   * @param params.id - Note id.
   * @param params.content - Content of the note.
   * @returns The updated note.
   */
  async updateNote({
    id,
    content,
  }: {
    id: string;
    content: string;
  }): Promise<LessonStudentNote> {
    const { body: note } = await this.http
      .put(`/api/v1/school/lesson-notes/${e(id)}`)
      .send({
        content,
      });

    return note;
  }

  /**
   * Delete a note.
   *
   * @param params - Params.
   * @param params.id - Note id.
   * @returns - Note successfully deleted.
   */
  async deleteNote({ id }: { id: string }): Promise<boolean> {
    const { status } = await this.http.delete(
      `/api/v1/school/lesson-notes/${e(id)}`,
    );

    return status === 204;
  }
}

import { type StudentCourse } from '@eversity/types/domain';

import { useUser } from './queries/user';

export const useUserCourse = (
  courseId: string,
  classId: string,
): StudentCourse => {
  const { data: user } = useUser();

  return user.courses.find(
    (userCourse) =>
      userCourse.course.id === courseId && userCourse.class.id === classId,
  );
};
